/// <reference lib="dom" />
import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, StaticQuery } from "gatsby";

import Header from "@/components/Header";
import Footer from "@/components/Footer";
import "@/styles/index.scss";

import Headshot from "@/images/headshot.png";

// markup
const AboutPage: React.FC<DefaultPageProps> = ({ location, data }) => {
  const pageTitle = "Sonali Zaveri | Design Leader | Portfolio";

  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta
          property="og:url"
          content={data.site.siteMetadata.siteUrl + location.pathname}
        />
        <meta property="og:title" content={pageTitle} />
        <meta name="twitter:title" content={pageTitle} />
      </Helmet>
        <main>
        <div className="hero hero--about">
          <Header active="about" />
          <div className="content-container">
            <div className="columns">
              <div className="column">
                <h1>Hi there! 👋</h1>
                <p>
                  I’m Sonali! I was born and raised in the San Francisco Bay
                  Area, and after a brief stint in London, I’m happy to call the
                  Bay home again. After graduating with a degree in Design and
                  Computer Science, I embarked into the world of work as a
                  designer first and front-end developer second. As a hybrid
                  designer / developer, I’ve built an expertise with design
                  systems and accessibility (I’m a Certified Professional in Web
                  Accessibility!)
                </p>
                <p>
                  My career has taken me through many industries, team sizes,
                  and customer types. I count myself lucky to take on challenges
                  that are not only exciting, but give me the opportunity to
                  make a positive contribution to society.
                </p>
                <p>
                  Outside of work, I’m a fan of many things including but not
                  limited to - cookies, puzzles, sour beer, fantasy books, RPG
                  video games, donuts, painting the walls of my new home, dogs,
                  hand pulled noodles, convincing my husband we need to paint
                  more walls, cats, cooking, cocktails, UC Davis, business &
                  psychology books, travel and tacos. Not necessarily in that
                  order.
                </p>
              </div>
              <div className="column">
                <img
                  className="headshot"
                  src={Headshot}
                  alt="Headshot of Sonali"
                />
              </div>
            </div>
          </div>
        </div>
        <section>
          <div className="content-container">
            <h2>Past Experience</h2>
            <p className="large">
              For full details about my past experience{" "}
              <a href="/downloads/sonalizaveri_resume.pdf" target="blank">
                download my resume
              </a>{" "}
              or{" "}
              <a href="https://linkedin.com/in/sdzaveri" target="blank">
                check out my LinkedIn
              </a>
              .
            </p>
            <div className="experience-item">
              <h3>
                Design & Operations Lead <small>ideas42 Ventures</small>
              </h3>
              <p className="quiet">March 2021 to present</p>
              <p>
                ideas42 Ventures is a venture studio that recruits entrepreneurs
                from under represented communities and supports them in the
                development of impact focused businesses and software products.
              </p>
            </div>
            <div className="experience-item">
              <h3>
                Design Manager & Product Designer / Developer
                <small>OpenGov</small>
              </h3>
              <p className="quiet">August 2019 to March 2021</p>
              <p>
                OpenGov is a mission-focused company that builds transparency,
                budgeting and data management software for local government.
              </p>
            </div>
            <div className="experience-item">
              <h3>
                Senior UX Designer / Developer
                <small>Oracle Utilities (Formerly Opower)</small>
              </h3>
              <p className="quiet">November 2016 to August 2018</p>
              <p>
                Oracle Utilities (formerly Opower) builds tools that apply
                behavioral science to encourage utility customers to use less
                energy.
              </p>
            </div>
            <div className="experience-item">
              <h3>Other Past Experience</h3>
              <p>
                <strong>UI Designer</strong> at Google (Contract for Internal
                Education Tools), April 2016 to November 2016
              </p>
              <p>
                <strong>UI Designer and Front-End Developer</strong> at
                BetterHelp, June 2015 to April 2016
              </p>
              <p>
                <strong>Interaction Designer</strong> at HP Enterprise,
                September 2013 to May 2015
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default (props: DefaultPageProps): React.ReactNode => (
  <StaticQuery
    query={graphql`
      query AboutPageQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={(data) => <AboutPage data={data} {...props} />}
  />
);
